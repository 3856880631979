<template>
  <div>
    <v-dialog
      v-model="showEditExercise"
      :fullscreen="isPhone"
      persistent
      width="800"
      overlay-opacity=".95"
    >
      <v-card
        tile
        class="charcoal charcoalTile d-flex flex-column noscroll"
        elevation="5"
      >
        <BaseCloseDialogHeader
          heading="Exercise Details"
          @cancel="showEditExercise = false"
        />
        <v-card-text :class="textSize + ' paper--text px-2'">
          {{ selectedForEdit.name }}
        </v-card-text>
        <v-sheet class="transparent">
          <template
            v-if="
              selectedForEdit.resistance == 'Yes' ||
                (selectedForEdit.track_time != 'Yes' &&
                  selectedForEdit.track_distance != 'Yes')
            "
          >
            <v-card-title :class="textSize + ' silver--text pb-0 pr-0'">
              Select RepScheme
              <v-spacer />
            </v-card-title>
            <v-card-text class="py-0">
              <v-autocomplete
                filled
                allow-overflow
                dark
                :key="switchNum"
                v-model="selectedForEdit.repschemeid"
                :items="repSchemes"
                large
                chips
                deletable-chips
                item-text="name"
                item-value="id"
                class=" pl-5"
                @input="setRepschemeDetails"
              />
              <v-card-actions class="mt-n4 silver--text pa-0">
                <v-spacer />
                <BaseActionButton
                  icon="mdi-plus-circle-outline"
                  label="Or Add Another RepScheme"
                  title="Add Another RepScheme"
                  plain
                  small
                  color="paper"
                  @clickedThis="$router.push('/repschemes/new')"
                />
              </v-card-actions>
              <v-divider />
              <template v-if="isSelected">
                <v-card-title
                  :class="textSize + ' progressActive--text px-0 pb-0'"
                >
                  Info
                </v-card-title>
                <v-card-text
                  :class="textSizeXSmall + ' silver--text px-0 pb-0'"
                >
                  {{ currentRepscheme.display_name }}
                  <br />
                  Sets: {{ currentRepscheme.sets }}<br />
                  Utility:{{ currentRepscheme.utility }}<br />
                  Scheme Type: {{ currentRepscheme.type }}
                </v-card-text>
                <template v-if="currentRepscheme.description != null">
                  <v-card-title class="paper--text pa-0">
                    Description
                  </v-card-title>
                  <v-card-text
                    class="px-0 silver--text"
                    v-html="currentRepscheme.description"
                  >
                  </v-card-text>
                </template>
              </template>
            </v-card-text>
          </template>
          <v-card-text
            v-if="
              selectedForEdit.track_time == 'Yes' ||
                selectedForEdit.track_distance == 'Yes'
            "
          >
            <v-row v-if="selectedForEdit.track_distance == 'Yes'" dense>
              <v-col cols="6">
                <v-text-field
                  dark
                  filled
                  v-model="selectedForEdit.distance_metric"
                  min="0"
                  label="Distance (Km)"
                  type="number"
                  prepend-inner-icon="mdi-dots-horizontal-circle"
                  @input="calculateImperial"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dark
                  filled
                  v-model="selectedForEdit.distance_imperial"
                  min="0"
                  label="Distance (miles)"
                  type="number"
                  prepend-inner-icon="mdi-dots-horizontal-circle"
                  @input="calculateMetric"
                />
              </v-col>
            </v-row>
            <v-row v-if="selectedForEdit.track_time == 'Yes'" dense>
              <v-col cols="6">
                <v-text-field
                  dark
                  filled
                  v-model="selectedForEdit.time"
                  min="0"
                  max="36000"
                  label="Time (minutes)"
                  type="number"
                  prepend-inner-icon="mdi-clock-in"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="
                selectedForEdit.track_time == 'No' &&
                  selectedForEdit.track_distance == 'No'
              "
              dense
            >
              <v-col cols="6">
                <v-text-field
                  dark
                  filled
                  v-model="selectedForEdit.reps"
                  min="0"
                  max="500"
                  label="Reps"
                  type="number"
                  prepend-inner-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.weight"
                  ref="weightm"
                  label="Weight (Dudes)"
                  type="number"
                  prepend-inner-icon="mdi-weight"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dark
                  filled
                  v-model="selectedForEdit.weightf"
                  ref="weightf"
                  label="Weight (Dudettes)"
                  type="number"
                  prepend-inner-icon="mdi-weight"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-trash-can-outline"
            :large="false"
            label="Delete"
            plain
            title="Delete Exercise"
            color="orange"
            @clickedThis="deleteExercise"
          />
          <v-spacer />
          <BaseActionButton
            dark
            :disabled="!isSelected"
            icon="mdi-checkbox-outline"
            large
            title="Done Editing"
            label="Done"
            plain
            color="paper"
            @clickedThis="done(selectedForEdit)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-chip-group v-if="exercisesList.length > 0" column>
      <span v-for="sel in exercisesList" :key="sel.id + sel.sort">
        <v-chip
          close
          close-icon="mdi-close-circle-outline"
          x-large
          dark
          @click="editExercise(sel)"
          @click:close="removeExercise(sel)"
          color="charcoal lighten-1"
          class="  elevation-1 rounded-lg text-truncate"
          text-color="progressActive"
          style="max-width: 350px"
          title="Click to change exercise settings (sets, reps, weight, distance, etc)"
        >
          <v-card-text class="pl-0 pt-2">
            <span :class="textSizeXSmall + ' progressActive--text short '" >
              {{ sel.sort }}. {{ trimString(sel.name, 33) }}
            </span>
            <v-spacer />
            <span
              v-if="
                sel.resistance == 'Yes' ||
                  (sel.track_time != 'Yes' && sel.track_distance != 'Yes')
              "
              class="paper--text caption pl-2"
            >
              {{ chipDetails(sel).sets }} sets. {{ chipDetails(sel).name }}
            </span>
            <span
              v-if="sel.track_time == 'Yes' || sel.track_distance == 'Yes'"
              class="paper--text text--lighten-3 caption"
            >
              <span v-if="sel.track_distance == 'Yes'">
                {{ chipDetailsDistance(sel) }}
              </span>
              <span v-else-if="sel.track_time == 'Yes'">
                {{ chipDetailsTime(sel) }}
              </span>
              <span v-else>{{ chipDetailsWOD(sel) }}</span>
            </span>
          </v-card-text>
        </v-chip>
      </span>
    </v-chip-group>
  </div>
</template>
<script>
import { builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    repSchemes: {
      type: Array
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      selectedForEdit: {},
      currentRepscheme: {},
      isSelected: true,
      showEditExercise: false,
      switchNum: 0,
      loaded: false,
      toMiles: 0.621371,
      toKmeters: 1.60934
    }
  },
  mounted() {
    this.loaded = true
  },
  computed: {
    ...builder
  },
  methods: {
    deleteExercise() {
      this.removeExercise(this.selectedForEdit)
      this.showEditExercise = false
    },
    calculateImperial() {
      this.selectedForEdit.distance_imperial = this.threeDigits(
        this.selectedForEdit.distance_metric * this.toMiles
      )
    },
    calculateMetric() {
      this.selectedForEdit.distance_metric = this.threeDigits(
        this.selectedForEdit.distance_imperial * this.toKmeters
      )
    },
    setRepschemeDetails() {
      var index = -1
      index = this.repSchemes.findIndex(
        p => p.id == this.selectedForEdit.repschemeid
      )
      if (index >= 0) {
        this.currentRepscheme = this.repSchemes[index]
        this.isSelected = true
        this.selectedForEdit.hasRepScheme = 'Yes'
        this.selectedForEdit.sets = this.currentRepscheme.sets
      } else {
        this.isSelected = false
        this.selectedForEdit.hasRepScheme = 'No'
      }
    },
    prettyfy(index, sel) {
      return (
        index +
        1 +
        '. ' +
        sel.name
          .split(' ')
          .slice(0, 4)
          .join(' ') +
        '...'
      )
    },
    removeExercise(sel) {
      this.$emit('remove', sel)
    },
    editExercise(sel) {
      if (this.type == 'Circuit') return
      this.selectedForEdit = sel
      this.setRepschemeDetails()
      this.showEditExercise = true
    },
    done(sel) {
      this.$emit('done', sel)
      this.showEditExercise = false
    },
    chipDetails(sel) {
      let details = new Object()
      var index = this.repSchemes.findIndex(p => p.id == sel.repschemeid)
      if (index >=0 ) {
        let scheme = this.repSchemes[index]

        details.name = scheme.name + ': ' + scheme.scheme
        details.utility = scheme.utility
        details.sets = scheme.sets
        details.type = scheme.type
        details.description = scheme.description
      }

      return details
    },
    chipDetailsWOD(sel) {
      let part2 = ''
      let part3 = ''
      let ret = (this.isPhone ? 'Tap' : 'Click') + ' to set reps & weight'
      if (sel.weight >= 0) {
        part2 = sel.weight + ' lbs(M) '
        part3 = sel.weightf + ' lbs(F)  '
        ret = sel.reps + ' reps @ ' + part2 + part3
      }
      return ret
    },
    chipDetailsDistance(sel) {
      if (sel.distance_metric == undefined) return
      return sel.distance_metric + ' Km / ' + sel.distance_imperial + ' miles'
    },
    chipDetailsTime(sel) {
      if (sel.time == undefined) return
      return sel.time + ' seconds'
    }
  }
}
</script>
<style scoped>
.short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
